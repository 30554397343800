import React, { useState } from "react";
import { Wrapper } from "./Pool.styled";
import { WrapperVariants } from "./Pool.variants";
import PoolOnboarding from "./PoolOnboarding/PoolOnboarding";
import PoolPair from "./PoolPair/PoolPair";
import PoolLiquidity from "./PoolLiquidity/PoolLiquidity";
import PoolImport from "./PoolImport/PoolImport";
import { Helmet } from "react-helmet";

const Pool = () => {
  const [screen, setScreen] = useState(0);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HogiFin - Pool</title>
      </Helmet>
      <Wrapper variants={WrapperVariants} initial="hidden" animate="visible">
        {screen === 0 && <PoolOnboarding setScreen={setScreen} />}
        {screen === 1 && <PoolPair setScreen={setScreen} />}
        {screen === 2 && <PoolLiquidity setScreen={setScreen} />}
        {screen === 3 && <PoolImport setScreen={setScreen} />}
      </Wrapper>
    </>
  );
};

export default Pool;
