import React from "react";
import { useSelector } from "react-redux";
import {
  BackIcon,
  Card,
  ExchangeIcon,
  Header,
  HeaderText,
  Hint,
  HintHeading,
  HintText,
  SettingsIcon,
  SwapButton,
  SwapForm,
} from "./PoolLiquidity.styled";
import { CardVariants } from "./PoolLiquidity.variants";
import backDark from "../../../assets/images/back-arrow-dark.svg";
import backLight from "../../../assets/images/back-arrow-light.svg";
import settingsDark from "../../../assets/images/settings-dark.svg";
import settingsLight from "../../../assets/images/settings-light.svg";
import InputPrimary from "../../_common/InputPrimary/InputPrimary";
import pairDark from "../../../assets/images/pair-dark.svg";
import pairLight from "../../../assets/images/pair-light.svg";

const PoolLiquidity = ({ setScreen }) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <Card variants={CardVariants} initial="hidden" animate="visible">
      <Header>
        <BackIcon
          onClick={() => setScreen(0)}
          src={isDarkTheme ? backDark : backLight}
        />
        <HeaderText>Add Liquidity</HeaderText>
        <SettingsIcon src={isDarkTheme ? settingsDark : settingsLight} />
      </Header>
      <Hint>
        <HintHeading>Tip:</HintHeading>
        <HintText>
          When you add liquidity, you will receive pool tokens representing your
          position. These tokens automatically earn fees proportional to your
          share of the pool, and can be redeemed at any time.
        </HintText>
      </Hint>
      <SwapForm>
        <InputPrimary label="Input" />
        <ExchangeIcon src={isDarkTheme ? pairDark : pairLight} />
        <InputPrimary label="Input" />
        <SwapButton>Connect a wallet</SwapButton>
      </SwapForm>
    </Card>
  );
};

export default PoolLiquidity;
