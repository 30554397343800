import styled from "styled-components";
import { device } from "../../../theme/breakpoints";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 640px;
  min-height: 150px;
  padding: 20px 20px;
  border-radius: 15px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.color.cardBg};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const CardHeading = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
`;

export const CardText = styled.div`
  font-size: 14px;
  line-height: 1.2rem;
  width: 100%;
  margin-bottom: 10px;
`;

export const CardLink = styled.div`
  font-size: 14px;
  line-height: 1.2rem;
  width: 100%;
  cursor: pointer;
  text-decoration: underline;
`;

export const LiquidityWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const LiquidityText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
  @media ${device.tablet} {
    margin-bottom: 0;
    justify-content: flex-start;
  }
`;

export const LiquidityButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LiquidityButtonOne = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: ${(props) => props.theme.color.buttonOneBg};
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.buttonOneText};
  @media ${device.tablet} {
    width: 180px;
  }
`;

export const LiquidityButtonTwo = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: ${(props) => props.theme.color.buttonTwoBg};
  border: 1px solid transparent;
  cursor: pointer;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.buttonTwoText};
  @media ${device.tablet} {
    width: 180px;
  }
`;

export const UserLiquidityWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  min-height: 120px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.bodyTextSecondary};
  margin-bottom: 20px;
  font-size: 14px;
`;

export const ImportLinkWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  font-weight: 500;
  color: ${(props) => props.theme.color.bodyTextPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const ImportLink = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: ${(props) => props.theme.color.linkColor};
  cursor: pointer;
  margin-left: 10px;
`;
