const dark = {
  color: {
    //Global
    appBackground: "#0D163A",
    bodyTextPrimary: "#ffffff",
    bodyTextSecondary: "#B6B9C4",
    inputPrimaryBorder: "#BBBEC9",
    inputPrimaryText: "#BBBEC8",
    inputPrimarySelectorText: "#ffffff",
    inputPrimarySelectorBg: "#242E56",
    cardBg: "#1C2549",
    buttonOneBg: "#242E56",
    buttonOneText: "#BDC0CC",
    buttonTwoBg: "#6829E1",
    buttonTwoText: "#FFFFFF",
    linkColor: "#6829E1",
    hintBg: "#242E56",
    hintText: "#BDC0CC",
    selectorPrimaryBorder: "#BBBEC9",
    emptyContentBorder: "#BBBEC9",
    //Navigation
    navBackground: "#1C2549",
    navBorder: "transparent",
    navLinkInactive: "#BBBEC8",
    navLinkActive: "#ffffff",
    actionButtonBg: "#242E56",
    actionButtonTextColor: "#D2BFF6",
  },
};

export default dark;
