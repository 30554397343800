import React from "react";
import { CardVariants } from "./PoolPair.variants";
import {
  BackIcon,
  Card,
  ExchangeIcon,
  Header,
  HeaderText,
  Hint,
  HintHeading,
  HintText,
  SettingsIcon,
  SwapButton,
  SwapForm,
} from "./PoolPair.styled";
import settingsDark from "../../../assets/images/settings-dark.svg";
import settingsLight from "../../../assets/images/settings-light.svg";
import InputPrimary from "../../_common/InputPrimary/InputPrimary";
import pairLight from "../../../assets/images/pair-light.svg";
import pairDark from "../../../assets/images/pair-dark.svg";
import backLight from "../../../assets/images/back-arrow-light.svg";
import backDark from "../../../assets/images/back-arrow-dark.svg";
import { useSelector } from "react-redux";

const PoolPair = ({ setScreen }) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <Card variants={CardVariants} initial="hidden" animate="visible">
      <Header>
        <BackIcon
          onClick={() => setScreen(0)}
          src={isDarkTheme ? backDark : backLight}
        />
        <HeaderText>Create a Pair</HeaderText>
        <SettingsIcon src={isDarkTheme ? settingsDark : settingsLight} />
      </Header>
      <Hint>
        <HintHeading>You are the first liquidity provider.</HintHeading>
        <HintText>
          The ratio of tokens you add will set the price of this pool.
        </HintText>
        <HintText>
          Once you are happy with the rate click supply to review.
        </HintText>
      </Hint>
      <SwapForm>
        <InputPrimary label="Input" />
        <ExchangeIcon src={isDarkTheme ? pairDark : pairLight} />
        <InputPrimary label="Input" />
        <SwapButton>Connect a wallet</SwapButton>
      </SwapForm>
    </Card>
  );
};

export default PoolPair;
