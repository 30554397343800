import styled from "styled-components";
import { motion } from "framer-motion";
import { device } from "../../theme/breakpoints";

export const Wrapper = styled(motion.div)`
  width: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
`;

export const Content = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 640px;
  min-height: 150px;
  padding: 20px 20px;
  border-radius: 15px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.color.cardBg};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const CardHeading = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
`;

export const CardText = styled.div`
  font-size: 14px;
  line-height: 1.2rem;
  width: 100%;
  margin-bottom: 10px;
`;

export const CardLink = styled.div`
  font-size: 14px;
  line-height: 1.2rem;
  width: 100%;
  cursor: pointer;
  text-decoration: underline;
`;

export const FinanceWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const FinanceHeading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
  @media ${device.tablet} {
    margin-bottom: 0;
    justify-content: flex-start;
  }
`;

export const FinanceText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 20px;
  @media ${device.tablet} {
    margin-bottom: 0;
    justify-content: flex-end;
  }
`;

export const ActivePoolsWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  min-height: 90px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.bodyTextSecondary};
  margin-bottom: 20px;
  font-size: 14px;
  border: 1px solid ${(props) => props.theme.color.emptyContentBorder};
  border-radius: 15px;
`;
