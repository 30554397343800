import React from "react";
import {
  Card,
  CardHeading,
  CardLink,
  CardText,
  ImportLink,
  ImportLinkWrapper,
  LiquidityButtonOne,
  LiquidityButtonTwo,
  LiquidityButtonWrapper,
  LiquidityText,
  LiquidityWrapper,
  UserLiquidityWrapper,
  Wrapper,
} from "./PoolOnboarding.styled";
import { WrapperVariants } from "./PoolOnboarding.variants";

const PoolOnboarding = ({ setScreen }) => {
  return (
    <Wrapper variants={WrapperVariants} initial="hidden" animate="visible">
      <Card>
        <CardHeading>Liquidity provider rewards</CardHeading>
        <CardText>
          Liquidity providers earn a 0.3% fee on all trades proportional to
          their share of the pool. Fees are added to the pool, accrue in real
          time and can be claimed by withdrawing your liquidity.
        </CardText>
        <CardLink>Read more about providing liquidity</CardLink>
      </Card>
      <LiquidityWrapper>
        <LiquidityText>Your Liquidity</LiquidityText>
        <LiquidityButtonWrapper>
          <LiquidityButtonOne onClick={() => setScreen(1)}>
            Create a Pair
          </LiquidityButtonOne>
          <LiquidityButtonTwo onClick={() => setScreen(2)}>
            Add Liquidity
          </LiquidityButtonTwo>
        </LiquidityButtonWrapper>
      </LiquidityWrapper>
      <UserLiquidityWrapper>
        Connect to a wallet to view your liquidity.
      </UserLiquidityWrapper>
      <ImportLinkWrapper>
        Don't see a pool you joined?
        <ImportLink onClick={() => setScreen(3)}>Import it.</ImportLink>
      </ImportLinkWrapper>
    </Wrapper>
  );
};

export default PoolOnboarding;
