import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.color.selectorPrimaryBorder};
  cursor: pointer;
`;

export const Left = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const LeftIcon = styled.img`
  width: 30px;
  height: auto;
  margin-right: 15px;
`;

export const LeftText = styled.div`
  font-size: 18px;
`;

export const RightIcon = styled.img`
  width: 18px;
  height: auto;
`;
