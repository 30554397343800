import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/_rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";

const configureStore = () => {
  const middleware = [thunk];
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  return store;
};

export default configureStore;
