import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${(props) => props.theme.color.appBackground};
  ${(props) => {
    return props.theme.isDarkTheme
      ? `background: ${(props) => props.theme.color.appBackground}`
      : `background: linear-gradient(120deg, rgba(223,227,235,1) 0%, rgba(235,215,220,1) 100%)`;
  }};
  width: 100%;
  min-height: 100vh;
`;

const AppWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default AppWrapper;
