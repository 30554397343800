const light = {
  color: {
    //Global
    appBackground: "#E3E3E3",
    bodyTextPrimary: "#000000",
    bodyTextSecondary: "#585653",
    inputPrimaryBorder: "#4C4C4C",
    inputPrimaryText: "#4C4C4C",
    inputPrimarySelectorText: "#000000",
    inputPrimarySelectorBg: "#D2BFF6",
    cardBg: "#ffffff",
    buttonOneBg: "#D2BFF6",
    buttonOneText: "#6829E1",
    buttonTwoBg: "#6829E1",
    buttonTwoText: "#FFFFFF",
    linkColor: "#6829E1",
    hintBg: "#D2BFF6",
    hintText: "#6829E1",
    selectorPrimaryBorder: "#4C4C4C",
    emptyContentBorder: "#4C4C4C",
    //Navigation
    navBackground: "transparent",
    navBorder: "#ACACAC",
    navLinkInactive: "#464646",
    navLinkActive: "#000000",
    actionButtonBg: "#6829E1",
    actionButtonTextColor: "#D2BFF6",
  },
};

export default light;
