import React from "react";
import {
  Card,
  Wrapper,
  Header,
  HeaderText,
  SettingsIcon,
  SwapForm,
  SwapButton,
  ExchangeIcon,
} from "./Swap.styled";
import { useSelector } from "react-redux";
import settingsLight from "../../assets/images/settings-light.svg";
import settingsDark from "../../assets/images/settings-dark.svg";
import exchangeLight from "../../assets/images/exchange-arrow-light.svg";
import exchangeDark from "../../assets/images/exchange-arrow-dark.svg";
import InputPrimary from "../_common/InputPrimary/InputPrimary";
import { WrapperVariants } from "./Swap.variants";
import { Helmet } from "react-helmet";

const Swap = () => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HogiFin - Swap</title>
      </Helmet>
      <Wrapper variants={WrapperVariants} initial="hidden" animate="visible">
        <Card>
          <Header>
            <HeaderText>Swap</HeaderText>
            <SettingsIcon src={isDarkTheme ? settingsDark : settingsLight} />
          </Header>
          <SwapForm>
            <InputPrimary label="From" />
            <ExchangeIcon src={isDarkTheme ? exchangeDark : exchangeLight} />
            <InputPrimary label="To" />
            <SwapButton>Connect a wallet</SwapButton>
          </SwapForm>
        </Card>
      </Wrapper>
    </>
  );
};

export default Swap;
