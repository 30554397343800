import React from "react";
import {
  Left,
  LeftIcon,
  LeftText,
  RightIcon,
  Wrapper,
} from "./SelectorPrimary.styled";
import coinIcon from "../../../assets/coins/eth-icon.svg";
import arrowLight from "../../../assets/images/arrow-down-dark.svg";
import arrowDark from "../../../assets/images/arrow-down-light.svg";
import { useSelector } from "react-redux";

const SelectorPrimary = () => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <Wrapper>
      <Left>
        <LeftIcon src={coinIcon} alt="coin" />
        <LeftText>ETH</LeftText>
      </Left>
      <RightIcon src={isDarkTheme ? arrowDark : arrowLight} />
    </Wrapper>
  );
};

export default SelectorPrimary;
