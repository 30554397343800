import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Wrapper,
  Container,
  LogoLinksDiv,
  LogoImg,
  NavMenuActions,
  ActionIcon,
  ActionButton,
  NavLinksWrapper,
  StyledNavLink,
  MenuIcon,
  BurgerMenuDiv,
} from "./Navigation.styled";
import { WrapperVariants } from "./Navigation.variants";
import logo from "../../../assets/images/nav-logo.svg";
import menuDark from "../../../assets/images/menu-dark.svg";
import menuLight from "../../../assets/images/menu-light.svg";
import toggleLight from "../../../assets/images/toggle-light.svg";
import toggleDark from "../../../assets/images/toggle-dark.svg";
import moreLight from "../../../assets/images/more-light.svg";
import moreDark from "../../../assets/images/more-dark.svg";
import { toggleTheme } from "../../../store/actions/themeActions";

const Navigation = () => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <Wrapper variants={WrapperVariants} initial="hidden" animate="visible">
      <Container>
        <LogoLinksDiv>
          <Link to="/">
            <LogoImg src={logo} alt="logo" />
          </Link>
          <NavLinksWrapper>
            <StyledNavLink exact to="/">
              Swap
            </StyledNavLink>
            <StyledNavLink exact to="/pool">
              Pool
            </StyledNavLink>
            <StyledNavLink exact to="/finance">
              Hogi Finance
            </StyledNavLink>
            <StyledNavLink exact to="/vote">
              Vote
            </StyledNavLink>
            <StyledNavLink exact to="/charts">
              Charts
            </StyledNavLink>
          </NavLinksWrapper>
        </LogoLinksDiv>
        <BurgerMenuDiv>
          <MenuIcon src={isDarkTheme ? menuDark : menuLight} alt="menu" />
        </BurgerMenuDiv>
        <NavMenuActions>
          <ActionButton special width={"52px"}>
            Hogi
          </ActionButton>
          <ActionButton width={"200px"}>Connect to a wallet</ActionButton>
          <ActionButton width={"52px"} onClick={() => dispatch(toggleTheme())}>
            <ActionIcon src={isDarkTheme ? toggleLight : toggleDark} />
          </ActionButton>
          <ActionButton width={"52px"}>
            <ActionIcon src={isDarkTheme ? moreDark : moreLight} />
          </ActionButton>
        </NavMenuActions>
      </Container>
    </Wrapper>
  );
};

export default Navigation;
