import React from "react";
import { WrapperVariants } from "./Vote.variants";
import { Helmet } from "react-helmet";
import {
  Wrapper,
  Content,
  Card,
  CardHeading,
  CardLink,
  CardText,
  VoteWrapper,
  ActiveProposalsWrapper,
  VoteHeading,
} from "./Vote.styled";

const Vote = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HogiFin - Vote</title>
      </Helmet>
      <Wrapper variants={WrapperVariants} initial="hidden" animate="visible">
        <Content>
          <Card>
            <CardHeading>Hogi Finance Governance</CardHeading>
            <CardText>
              Hogi tokens represent voting shares in Hogi Finance governance.
              You can vote on each proposal yourself or delegate your votes to a
              third party.
            </CardText>
            <CardLink>Read more about Hogi Finance governance</CardLink>
          </Card>
          <VoteWrapper>
            <VoteHeading>Proposals</VoteHeading>
          </VoteWrapper>
          <ActiveProposalsWrapper>No active proposals</ActiveProposalsWrapper>
        </Content>
      </Wrapper>
    </>
  );
};

export default Vote;
