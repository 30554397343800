import React from "react";
import {
  ArrowDownIcon,
  Bottom,
  CoinIcon,
  Input,
  Selector,
  SelectorText,
  Top,
  Wrapper,
} from "./InputPrimary.styled";
import { useSelector } from "react-redux";
import arrowLight from "../../../assets/images/arrow-down-dark.svg";
import arrowDark from "../../../assets/images/arrow-down-light.svg";
import coinIcon from "../../../assets/coins/eth-icon.svg";

const InputPrimary = ({ label }) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <Wrapper>
      <Top>{label}</Top>
      <Bottom>
        <Input placeholder="0.00" />
        <Selector>
          <CoinIcon src={coinIcon} />
          <SelectorText>ETH</SelectorText>
          <ArrowDownIcon
            src={isDarkTheme ? arrowDark : arrowLight}
            alt="arrow"
          />
        </Selector>
      </Bottom>
    </Wrapper>
  );
};

export default InputPrimary;
