import React from "react";
import { WrapperVariants } from "./Finance.variants";
import { Helmet } from "react-helmet";
import {
  Wrapper,
  Content,
  Card,
  CardHeading,
  CardLink,
  CardText,
  FinanceText,
  FinanceWrapper,
  ActivePoolsWrapper,
  FinanceHeading,
} from "./Finance.styled";

const Finance = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HogiFin - Finance</title>
      </Helmet>
      <Wrapper variants={WrapperVariants} initial="hidden" animate="visible">
        <Content>
          <Card>
            <CardHeading>Hogi Finance liquidity mining</CardHeading>
            <CardText>
              Deposit your liquidity provider token to receive Hogi, the Hogi
              Finance protocol governance token.
            </CardText>
            <CardLink>Read more about UNI</CardLink>
          </Card>
          <FinanceWrapper>
            <FinanceHeading>Participating pools</FinanceHeading>
            <FinanceText>Rewards have ended!</FinanceText>
          </FinanceWrapper>
          <ActivePoolsWrapper>No active pools</ActivePoolsWrapper>
        </Content>
      </Wrapper>
    </>
  );
};

export default Finance;
