import styled from "styled-components";
import { device } from "../../../theme/breakpoints";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

export const Wrapper = styled(motion.div)`
  width: 100%;
  height: 80px;
  background: ${(props) => props.theme.color.navBackground};
  border-bottom: 1px solid ${(props) => props.theme.color.navBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 20px;
  box-shadow: ${(props) => {
    return props.theme.isDarkTheme ? "0px 3px 6px rgba(0, 0, 0, 0.16)" : "";
  }};
  @media ${device.laptop} {
    padding: 0 40px 0 40px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoLinksDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const LogoImg = styled.img`
  width: 45px;
  height: auto;
  margin-right: 60px;
  cursor: pointer;
  user-select: none;
  @media (min-width: 1200px) {
    margin-right: 80px;
  }
`;

export const NavLinksWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.color.navLinkInactive};
  margin-right: 30px;
  white-space: nowrap;
  &.active {
    color: ${(props) => props.theme.color.navLinkActive};
    font-weight: 500;
  }
  @media (min-width: 1200px) {
    margin-right: 40px;
  }
`;

export const BurgerMenuDiv = styled.div`
  width: 20px;
  @media ${device.laptop} {
    display: none;
  }
`;

export const MenuIcon = styled.img`
  width: 100%;
  height: auto;
`;

export const NavMenuActions = styled.div`
  display: none;
  @media ${device.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;
  } ;
`;

export const ActionButton = styled.div`
  cursor: pointer;
  width: ${(props) => props.width};
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  color: ${(props) =>
    props.special ? "#ffffff" : props.theme.color.actionButtonTextColor};
  ${(props) => {
    return props.special
      ? "background: linear-gradient(#6829e1 0%, #947dbe 49.78%, #6829e1 100%);"
      : `background: ${props.theme.color.actionButtonBg}`;
  }};
`;

export const ActionIcon = styled.img`
  width: 20px;
  height: auto;
  user-select: none;
`;
