import { createGlobalStyle } from "styled-components";
import { normalize } from "polished";

const GlobalStyle = createGlobalStyle`
${normalize()}
html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, Arial, sans-serif;
  font-weight: 400;
  color: ${(props) => props.theme.color.bodyTextPrimary};
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

button {
  cursor: pointer;
}
`;

export default GlobalStyle;
