import styled from "styled-components";
import { motion } from "framer-motion";

export const Card = styled(motion.div)`
  width: 100%;
  max-width: 420px;
  min-height: 300px;
  padding: 30px 30px;
  border-radius: 40px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.color.cardBg};
  margin: 80px 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const BackIcon = styled.img`
  width: 25px;
  height: auto;
  cursor: pointer;
`;

export const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const SettingsIcon = styled.img`
  width: 20px;
  height: auto;
  cursor: pointer;
`;

export const SwapForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SwapButton = styled.button`
  width: 100%;
  height: 60px;
  outline: none;
  color: #ffffff;
  border-radius: 15px;
  border: 1px solid transparent;
  background: #6829e1;
  margin-top: 20px;
`;

export const ExchangeIcon = styled.img`
  cursor: pointer;
  width: 12px;
  height: auto;
  margin: 20px 0;
`;

export const Hint = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.color.hintBg};
  margin-bottom: 20px;
  border-radius: 15px;
`;

export const HintHeading = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 1.2rem;
  color: ${(props) => props.theme.color.hintText};
`;

export const HintText = styled.div`
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.2rem;
  color: ${(props) => props.theme.color.hintText};
`;
