import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import dark from "./theme/dark.theme";
import light from "./theme/light.theme";
import Swap from "./components/Swap/Swap";
import AppWrapper from "./components/_common/AppWrapper/AppWrapper";
import Navigation from "./components/_common/Navigation/Navigation";
import GlobalStyle from "./globalStyles";
import Pool from "./components/Pool/Pool";
import Finance from "./components/Finance/Finance";
import Vote from "./components/Vote/Vote";

const App = () => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  return (
    <ThemeProvider
      theme={isDarkTheme ? { ...dark, isDarkTheme } : { ...light, isDarkTheme }}
    >
      <GlobalStyle />
      <Router>
        <AppWrapper>
          <Navigation />
          <Switch>
            <Route exact path="/" component={Swap} />
            <Route exact path="/pool" component={Pool} />
            <Route exact path="/finance" component={Finance} />
            <Route exact path="/vote" component={Vote} />
          </Switch>
        </AppWrapper>
      </Router>
    </ThemeProvider>
  );
};

export default App;
