import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100px;
  padding: 10px 15px;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.color.inputPrimaryBorder};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Top = styled.div`
  width: 100%;
  color: ${(props) => props.theme.color.swapInputText};
  margin-bottom: 20px;
  font-size: 14px;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
  color: ${(props) => props.theme.color.inputPrimaryText};
  background: transparent;
  border: 1px solid transparent;
  font-size: 24px;
  font-weight: 500;
  &::placeholder {
    font-size: 24px;
    color: ${(props) => props.theme.color.inputPrimaryText};
    font-weight: 500;
  }
`;

export const Selector = styled.div`
  display: flex;
  align-content: center;
  cursor: pointer;
  background: ${(props) => props.theme.color.inputPrimarySelectorBg};
  padding: 10px 20px;
  border-radius: 5px;
`;

export const CoinIcon = styled.img`
  width: 20px;
  height: auto;
  margin-right: 10px;
`;

export const SelectorText = styled.div`
  color: ${(props) => props.theme.color.inputPrimarySelectorText};
  font-weight: 500;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const ArrowDownIcon = styled.img`
  width: 15px;
  height: auto;
`;
